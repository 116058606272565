@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@400;500;700&display=swap');

html {
    box-sizing: border-box;
    height: 100%;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

#root {
    min-width: 100vw;
    /* min-height: 100vh; */
    height: 100%;
    display: flex;
    background-color: #f3f7f9;
    /* overflow: scroll; */
}

:root {
    --viewport-height: 100%;
}

body {
    margin: 0;
    min-width: 100vw;
    /* min-height: 100vh; */
    height: 100%;
    display: flex;
    /* overflow: scroll; */
    font-family: 'Inter Tight', 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* 
h1: 26,
h2: 24,
h3: 20,
h4: 18,
h5: 16,
h6: 14
*/

h1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
}

h2 {
    all: unset;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

h4 {
    all: unset;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

h5 {
    all: unset;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;
}

h6 {
    all: unset;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

/* work-sans-regular - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/work-sans-v17-latin-regular.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/work-sans-v18-latin-500.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

/* work-sans-600 - latin */
@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/fonts/work-sans-v17-latin-600.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

.install-banner {
    background: #f4f4f4;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
}

.ios-install-guide {
    background: #f4f4f4;
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
}
